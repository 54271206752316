/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/Auth';

import { Container, Dash } from './styles';

interface TransactionInfo2 {
  id: string;
  credit_card: string;
  terminal: string;
  transaction_date: string;
  value: string;
  status_sale: number;
}

interface TransactionInfo {
  id?: string;
  amount: string;
  card_number?: string;
  document_client: string;
  store: string;
  id_collaborator: string;
  name_collaborator: string;
  document_collaborator: string;
  sending_method: string; // EMAIL | WHATSAPP | SMS | TWO_CHANNELS -> EMAIL e WHATSAAPP | THREE_CHANNELS -> EMAIL, WHATSAAPP e SMS
  status: string; // CREATED | PAIDOUT | EXPIRED | CANCELED
  created?: Date;
}

const OperationsTable = () => {
  const { ListpaymentsByCollaboratorId, user } = useAuth();
  const [transactions, setTransactions] = useState<TransactionInfo[]>([]);

  const [dateStartFilter, setDateStartFilter] = useState('');
  const [dateEndFilter, setDateEndFilter] = useState('');
  const [cardNumberFilter, setcardNumberFilter] = useState('');
  const [terminalFilter, setterminalFilter] = useState('');
  const [sumValue, setSumValues] = useState(0);
  const [diffTime, setDiffTime] = useState(0);

  function getDate(date: string) {
    const newDate = new Date(date);

    const day = newDate.getDate().toString().padStart(2, '0');
    const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const year = newDate.getFullYear().toString();
    const hours = newDate.getHours().toString().padStart(2, '0');
    const minutes = newDate.getMinutes().toString().padStart(2, '0');

    return `${day}/${month}/${year} - ${hours}:${minutes}`;
  }

  function getStatus(status: string, created: Date) {
    if (status === 'PAIDOUT') {
      return 'Pago';
    }
    if (status === 'CREATED') {
      const now: Date = new Date();
      const diffInMinutes: number =
        Math.abs(now.getTime() - new Date(created).getTime()) / 60000;

      if (diffInMinutes >= 10) {
        return 'Expirado';
      }
      return 'Criado';
    }
    if (status === 'EXPIRED') {
      return 'Expirado';
    }
    if (status === 'CANCELED') {
      return 'Cancelado';
    }
    return ' -- ';
  }

  useEffect(() => {
    async function getTransactions() {
      const start = dateStartFilter || new Date().setUTCHours(0, 0, 0, 0);
      const end = dateEndFilter || new Date().setUTCHours(23, 59, 59, 999);

      const allTransactions = await ListpaymentsByCollaboratorId(user.id);

      const timeDiff = new Date(end).getTime() - new Date(start).getTime();
      const diffInDays = timeDiff / (1000 * 60 * 60 * 24);
      setDiffTime(diffInDays);

      const transactionsSelecteds = [] as TransactionInfo[];
      setTransactions(allTransactions);
      // let totalValue = 0;

      // if (cardNumberFilter !== '' && terminalFilter !== '') {
      //   allTransactions.map(item => {
      //     if (item.card_number === cardNumberFilter) {
      //       if (item.terminal === terminalFilter) {
      //         transactionsSelecteds.push(item);
      //         if (item.status_sale === 1) {
      //           totalValue += parseFloat(item.value);
      //         }
      //       }
      //     }
      //   });
      //   setTransactions(transactionsSelecteds as unknown as TransactionInfo[]);
      // } else if (cardNumberFilter !== '' && terminalFilter === '') {
      //   allTransactions.map(item => {
      //     if (item.credit_card === cardNumberFilter) {
      //       transactionsSelecteds.push(item);
      //       if (item.status_sale === 1) {
      //         totalValue += parseFloat(item.value);
      //       }
      //     }
      //   });
      //   setTransactions(transactionsSelecteds as unknown as TransactionInfo[]);
      // } else if (terminalFilter !== '' && cardNumberFilter === '') {
      //   allTransactions.map(item => {
      //     if (item.terminal === terminalFilter) {
      //       transactionsSelecteds.push(item);
      //       if (item.status_sale === 1) {
      //         totalValue += parseFloat(item.value);
      //       }
      //     }
      //   });
      //   setTransactions(transactionsSelecteds as unknown as TransactionInfo[]);
      // } else {
      //   setTransactions(allTransactions);
      //   allTransactions.map(item => {
      //     if (item.status_sale === 1) {
      //       totalValue += parseFloat(item.value);
      //     }
      //   });
      // }
      // setSumValues(totalValue);
    }
    getTransactions();
  }, [dateStartFilter, dateEndFilter, cardNumberFilter, terminalFilter]);

  return (
    <Container>
      <h2>Transações</h2>
      <h3>Aqui são mostradas todas as suas transações geradas via link.</h3>
      <div style={{ display: 'flex', flexDirection: 'row' }} />

      <div>
        <table>
          <thead>
            <tr>
              <th>Data</th>
              <th>Cartão</th>
              <th>Loja</th>
              <th>Status</th>
              <th>Valor</th>
              <th>Id</th>
            </tr>
          </thead>

          <tbody>
            {transactions.map(transaction => (
              <tr key={transaction.id} className="rowBory">
                <td
                  className={
                    transaction.status && transaction.status === 'PAIDOUT'
                      ? 'green'
                      : 'red'
                  }
                >
                  {transaction.created
                    ? getDate(transaction.created.toString())
                    : ' -- '}
                </td>
                {/* <td>test</td> */}
                <td>
                  {transaction.card_number ? transaction.card_number : ' -- '}
                </td>
                <td>{transaction.store}</td>
                {/* <td>
                  {transaction.status === 'PAIDOUT'
                    ? 'Pago'
                    : transaction.status === 'CREATED'
                    ? 'Criado'
                    : 'Expirado'}
                </td> */}
                <td>
                  {getStatus(transaction.status, transaction.created as Date)}
                </td>
                <td>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(parseFloat(transaction.amount))}
                </td>
                <td>{transaction.id}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {transactions.length <= 0 && <h2>Sem transações cadastradas</h2>}
      </div>
    </Container>
  );
};

export default OperationsTable;
