/* eslint-disable react/prop-types */
import React from 'react';

import { useAuth } from '../../hooks/Auth';
import logo from '../../assets/logo-wallet.svg';

import {
  Container,
  NavLink,
  Bars,
  NavMenu,
  NavButton,
  NavButtonLink,
} from './styles';

interface HeaderProps {
  isContactUs?: boolean;
  isDashBoard?: boolean;
  isblockCard?: boolean;
  isDashBoardAdmin?: boolean;
  isUserManagement?: boolean;
  isUserEdit?: boolean;
}

const HeaderMain: React.FC<HeaderProps> = () => {
  const { signOut, user } = useAuth();
  const levelSTypes = ['8077'];
  const levelATypes = ['8077', '1723'];
  const levelBTypes = ['8077', '1723', '1213'];
  const levelCTypes = ['8077', '1723', '1213', '2814'];
  return (
    <Container>
      <NavLink to="/home">
        <img src={logo} alt="wallet logo" width={30} />
      </NavLink>

      <Bars />

      <NavMenu>
        <NavLink to="/home" activeStyle={{ color: '#8ac5dd' }}>
          Home
        </NavLink>

        <NavLink to="/dashboard" activeStyle={{ color: '#8ac5dd' }}>
          Nova solicitação
        </NavLink>

        {levelCTypes.includes(user.user_type) && (
          <NavLink to="/changelimit" activeStyle={{ color: '#8ac5dd' }}>
            Alterar limite
          </NavLink>
        )}

        {levelATypes.includes(user.user_type) && (
          <NavLink to="/cardgenerate" activeStyle={{ color: '#8ac5dd' }}>
            Gerar n° de cartão
          </NavLink>
        )}

        {levelBTypes.includes(user.user_type) && (
          <NavLink to="/createuserweb" activeStyle={{ color: '#8ac5dd' }}>
            Novo usuário Web
          </NavLink>
        )}

        {levelATypes.includes(user.user_type) && (
          <NavLink to="/financial" activeStyle={{ color: '#8ac5dd' }}>
            Transações
          </NavLink>
        )}

        {levelBTypes.includes(user.user_type) && (
          <NavLink to="/searchuser" activeStyle={{ color: '#8ac5dd' }}>
            Pesquisar
          </NavLink>
        )}

        {levelBTypes.includes(user.user_type) && (
          <NavLink to="/newpayment" activeStyle={{ color: '#8ac5dd' }}>
            Link
          </NavLink>
        )}

        {levelBTypes.includes(user.user_type) && (
          <NavLink to="/financial_payment" activeStyle={{ color: '#8ac5dd' }}>
            Lista link
          </NavLink>
        )}
      </NavMenu>

      <NavButton>
        <NavButtonLink type="button" onClick={signOut}>
          Sair
        </NavButtonLink>
      </NavButton>
    </Container>
  );
};

export default HeaderMain;
