/* eslint-disable camelcase */
/* eslint-disable no-console */
// eslint-disable-next-line no-use-before-define
import React, { useRef, useCallback, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiCreditCard, FiUser } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import AuthContext from '../../context/AuthContext';

import logoImg from '../../assets/logo-wallet-pos.svg';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';
import Header from '../../components/HeaderMain';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import { Container, Content, AnimationContainer } from './styles';

interface UpdateUserFormData {
  cpf: string;
  limit: string;
}

const ChangeLimit: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const auth = useContext(AuthContext);

  const { updateUser } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: UpdateUserFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          cpf: Yup.string().required('CPF obrigatório'),
          limit: Yup.string().required('Limite obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await updateUser(data.cpf, 'total_limit', '', false, false, data.limit);
        addToast({
          type: 'success',
          title: 'Limite alterado',
          description: 'Solicitação executada.',
        });

        // history.push('/home');
      } catch (error) {
        console.log('ERROR :: ', error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na solicitação',
          description:
            'Verifique se o usuário existe ou se o CPF esta correto!',
        });
      }
    },
    [updateUser, addToast],
  );

  return (
    <>
      <Header />
      <Container>
        <Content>
          <AnimationContainer>
            <img src={logoImg} alt="wallet logo" />
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>Alteração de limite</h1>
              <Input
                name="cpf"
                maxLength={4}
                type="number"
                icon={FiUser}
                placeholder="CPF"
              />
              <Input
                name="limit"
                type="number"
                icon={FiCreditCard}
                placeholder="Limite (R$)"
              />
              <Button type="submit">Alterar</Button>
            </Form>
          </AnimationContainer>
        </Content>
      </Container>
    </>
  );
};

export default ChangeLimit;
