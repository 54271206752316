/* eslint-disable no-console */
import React, { useCallback, useRef } from 'react';
import { FiUser, FiChevronUp } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useAuth } from '../../hooks/Auth';

import Header from '../../components/HeaderMain';
import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container } from './styles';

interface SignInFormData {
  email: string;
  password: string;
}

const DashboarAdmin: React.FC = () => {
  const { signIn } = useAuth();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(async (data: SignInFormData) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        cpf: Yup.string().required('CPF obrigatório'),
        limit: Yup.string().required('Limite obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      // await signIn({
      //   email: data.email,
      //   password: data.password,
      // });
      // addToast({
      //   type: 'success',
      //   title: 'Solicitação efetuada',
      //   description: 'Solicitação em analise.',
      // });

      // history.push('/home');
    } catch (error) {
      console.log('ERROR :: ', error);
      if (error instanceof Yup.ValidationError) {
        // const errors = getValidationErrors(error);
        // formRef.current?.setErrors(errors);
      }
      // addToast({
      //   type: 'error',
      //   title: 'Erro na solicitação',
      //   description: 'E-mail não encontrado.',
      // });
    }
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>teste</h1>
          <div>
            <Input
              name="cpf"
              maxLength={4}
              type="number"
              icon={FiUser}
              placeholder="CPF"
            />
            <Input
              name="limit"
              type="number"
              icon={FiUser}
              placeholder="Limite (R$)"
            />
          </div>
          <Button type="submit">Alterar</Button>
          <Button type="submit">Alterar</Button>
        </Form>
      </Container>
    </>
  );
};

export default DashboarAdmin;
