import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
export const Description = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  max-height: 100px;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  align-self: center;

  h1 {
    font-size: 17px;
    text-align: center;
  }
`;

export const CardSelect = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  max-height: 300px;
  margin-left: 20%;
  margin-top: 50px;

  h1 {
    font-size: 18px;
    margin-left: 20px;
  }

  h2 {
    font-size: 16px;
    align-content: flex-start;
  }

  div {
    margin-top: 30px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  input {
    background: #8ac5dd;
  }
`;

export const Justificate = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  max-width: 700px;
  margin-left: 20%;

  span {
    align-self: flex-end;
    width: 180px;
  }

  h2 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  > textarea {
    height: 200px;
    border-radius: 8px;
    padding: 10px;
    border: solid 2px;
    resize: none;
  }
`;
