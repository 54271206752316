/* eslint-disable camelcase */
/* eslint-disable no-console */
// eslint-disable-next-line no-use-before-define
import React from 'react';
import { useHistory } from 'react-router-dom';

import creditcards from '../../assets/creditcards.png';
import pedido from '../../assets/pedido.png';
import creditlimit from '../../assets/creditlimit.png';
import adduser from '../../assets/adduser.png';
import financial from '../../assets/financial.png';
import phone from '../../assets/addphone.png';
import email from '../../assets/email.png';
import card from '../../assets/creditcard.png';
import search from '../../assets/search.png';
import operation from '../../assets/operation.png';
import list from '../../assets/list.png';

import Header from '../../components/HeaderMain';
import Card from '../../components/Card';

import { useAuth } from '../../hooks/Auth';

import { Container, Image, Content, ContentMenu } from './styles';

const Main: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const levelSTypes = ['8077'];
  const levelATypes = ['8077', '1723'];
  const levelBTypes = ['8077', '1723', '1213'];
  const levelCTypes = ['8077', '1723', '1213', '2814'];

  return (
    <>
      <Header />
      <Container>
        <Content>
          <ContentMenu>
            <Card
              onClick={() => {
                history.push('/dashboard');
              }}
            >
              <Image src={pedido} alt="equipment" />
              <text>Nova solicitação</text>
            </Card>

            {levelCTypes.includes(user.user_type) && (
              <Card
                onClick={() => {
                  history.push('/changelimit');
                }}
              >
                <Image src={creditlimit} alt="equipment" />
                <text>Alterar limite</text>
              </Card>
            )}

            {levelSTypes.includes(user.user_type) && (
              <Card
                onClick={() => {
                  history.push('/cardgenerate');
                }}
              >
                <Image src={creditcards} alt="equipment" />
                <text>Gerar n° de cartão</text>
              </Card>
            )}

            {levelBTypes.includes(user.user_type) && (
              <Card
                onClick={() => {
                  history.push('/createuserweb');
                }}
              >
                <Image src={adduser} alt="equipment" />
                <text>Novo Usuário Web</text>
              </Card>
            )}

            {levelBTypes.includes(user.user_type) && (
              <Card
                onClick={() => {
                  history.push('/changephone');
                }}
              >
                <Image src={phone} alt="Telefone" />
                <text>Alterar telefone</text>
              </Card>
            )}

            {levelATypes.includes(user.user_type) && (
              <Card
                onClick={() => {
                  history.push('/newpayment');
                }}
              >
                <Image src={operation} alt="Pesquisar" />
                <text>Link de pagamento</text>
              </Card>
            )}
          </ContentMenu>
          <ContentMenu>
            {levelBTypes.includes(user.user_type) && (
              <Card
                onClick={() => {
                  history.push('/changeemail');
                }}
              >
                <Image src={email} alt="E-mail" />
                <text>Alterar e-mail</text>
              </Card>
            )}

            {levelBTypes.includes(user.user_type) && (
              <Card
                onClick={() => {
                  history.push('/changecard');
                }}
              >
                <Image src={card} alt="Cartão de crédito" />
                <text>Alterar dados de cartão</text>
              </Card>
            )}

            {levelATypes.includes(user.user_type) && (
              <Card
                onClick={() => {
                  history.push('/financial');
                }}
              >
                <Image src={financial} alt="financial" />
                <text>Transações</text>
              </Card>
            )}

            {levelBTypes.includes(user.user_type) && (
              <Card
                onClick={() => {
                  history.push('/searchuser');
                }}
              >
                <Image src={search} alt="Pesquisar" />
                <text>Pesquisar usuário</text>
              </Card>
            )}

            {levelATypes.includes(user.user_type) && (
              <Card
                onClick={() => {
                  history.push('/financial_payment');
                }}
              >
                <Image src={list} alt="Pesquisar" />
                <text>Minhas vendas</text>
              </Card>
            )}
          </ContentMenu>

          <ContentMenu>
            {/* {levelATypes.includes(user.user_type) && (
              <Card
                onClick={() => {
                  history.push('/financial_payment');
                }}
              >
                <Image src={list} alt="Pesquisar" />
                <text>Minhas vendas</text>
              </Card>
            )} */}
          </ContentMenu>
        </Content>
      </Container>
    </>
  );
};

export default Main;
