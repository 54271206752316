/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-console */
import axios from 'axios';
import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/apiClient';

interface User {
  id: string;
  name: string;
  email: string;
  document: string;
  password: string;
  card_number: string;
  is_administrative_user: boolean;
  is_active: boolean;
  total_limit: string;
  used_limit: string;
  limit_released: string;
  access_web: boolean;
  first_access: boolean;
  accept_terms: boolean;
  card_password: string;
  card_date_validity: string;
  card_cvv: string;
  card_active: boolean;
  phone_number: string;
  preferred_name: string;
  rg: string;
  gender: string;
  card_name: string;
  birth_date: Date;
  nationality: string;
  number_of_dependents: string;
  marital_status: string;
  scholarity: string;
  mothers_name: string;
  mailing_address: string;
  property_size: string;
  partner_name: string;
  partner_email: string;
  user_type: string;
  person_public?: boolean;
  know_public_agent?: boolean;
}
interface UserWeb {
  name: string;
  email: string;
  document: string;
  password: string;
  card_number: string;
  is_active: boolean;
  is_administrative_user: boolean;
  access_web: boolean;
  first_access: boolean;
  accept_terms: boolean;
  card_password: string;
  card_cvv: string;
  card_active: boolean;
  user_type: string;
}

interface IPayment {
  id?: string;
  amount: string;
  card_number?: string;
  document_client: string;
  store: string;
  id_collaborator: string;
  name_collaborator: string;
  document_collaborator: string;
  sending_method: string; // EMAIL | WHATSAPP | SMS | TWO_CHANNELS -> EMAIL e WHATSAAPP | THREE_CHANNELS -> EMAIL, WHATSAAPP e SMS
  status: string; // CREATED | PAIDOUT | EXPIRED | CANCELED
  created?: Date;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthState {
  token: string;
  user: User;
}

interface TransactionInfo {
  id: string;
  credit_card: string;
  terminal: string;
  transaction_date: string;
  value: string;
  status_sale: number;
}

interface PhoneState {
  cpf_user: string;
  number: string;
  type: string;
}

interface AddressState {
  cpf_user: string;
  cep: string;
  road: string;
  district: string;
  number: string;
  city: string;
  state: string;
  residence_type: string;
  residence_status: string;
  corporate_name?: string;
}

interface SearchState {
  name: string;
  email: string;
  document: string;
  card_number: string;
  is_active: boolean;
  total_limit: string;
  used_limit: string;
  card_date_validity: string;
  phone_number: string;
}

interface CompanyState {
  cpf_user: string;
  cnpj: string;
  profession: string;
  branch_of_activity: string;
  wage: string;
  company_date_start: string;
  number_of_employees: string;
  income_extra?: string;
  income_description?: string;
}

interface ILocalUser {
  id: string;
  name: string;
  document: string;
  user_type: string;
  is_administrative_user: boolean;
  access_web: boolean;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  createUser(credentials: User): Promise<void>;
  createUserWeb(credentials: UserWeb): Promise<void>;
  createPayment(data: IPayment): Promise<void>;
  validatePayment(data: IPayment): Promise<void>;
  signOut(): void;

  updateUser(
    cpf?: string,
    description?: string,
    password?: string,
    first_access?: boolean,
    accept_terms?: boolean,
    total_limit?: string,
    phone_number?: string,
    card_cvv?: string,
    card_number?: string,
    email?: string,
  ): Promise<void>;

  setPhone(cpf_user: string, number: string, type: string): Promise<void>;

  setAdress(
    cpf_user: string,
    cep: string,
    road: string,
    district: string,
    number: string,
    city: string,
    state: string,
    residence_type: string,
    residence_status: string,
    corporate_name?: string,
  ): Promise<void>;

  setCompany(
    cpf_user: string,
    cnpj: string,
    profession: string,
    branch_of_activity: string,
    wage: string,
    company_date_start: string,
    number_of_employees: string,
    income_extra: string,
    income_description: string,
  ): Promise<void>;

  ListTransactionsByDate(
    startDate: string,
    endDate: string,
  ): Promise<TransactionInfo[]>;

  ListpaymentsByCollaboratorDocument(
    document_collaborator: string,
  ): Promise<IPayment[]>;

  ListpaymentsByCollaboratorId(id_collaborator: string): Promise<IPayment[]>;

  ListpaymentsById(id: string): Promise<IPayment>;

  searchUserByDocument(document: string): Promise<SearchState>;
  searchUserByCardNumber(card_number: string): Promise<SearchState>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Wallet:token');
    const user = localStorage.getItem('@Wallet:user');

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post<AuthState>('sessions', {
      email,
      password,
    });

    const { token, user } = response.data;
    if (!user.access_web) {
      return;
    }

    const localUser: ILocalUser = {
      id: user.id,
      name: user.name,
      document: user.document,
      user_type: user.user_type,
      is_administrative_user: user.is_administrative_user,
      access_web: user.access_web,
    };

    localStorage.setItem('@Wallet:token', token);
    localStorage.setItem('@Wallet:user', JSON.stringify(localUser));
    setData({ token, user });
  }, []);

  const searchUserByDocument = useCallback(async cpf => {
    const config = {
      headers: {
        Authorization: '6faaba1e62ca15419dca284d0da20d17398ca63b',
        'Content-Type': 'application/json',
      },
    };
    const response = await axios.post<SearchState>(
      'https://api.pathbeta.com/listUsers/document',
      {
        cpf,
      },
      config,
    );

    const user = response.data;

    return user;
  }, []);

  const searchUserByCardNumber = useCallback(async card_number => {
    const config = {
      headers: {
        Authorization: '6faaba1e62ca15419dca284d0da20d17398ca63b',
        'Content-Type': 'application/json',
      },
    };
    const response = await axios.post<SearchState>(
      'https://api.pathbeta.com/listUsers/card',
      {
        card_number,
      },
      config,
    );

    const user = response.data;

    return user;
  }, []);

  const setPhone = useCallback(async (cpf_user, number, type) => {
    const response = await api.post<PhoneState>('phone', {
      cpf_user,
      number,
      type,
    });
  }, []);

  const setAdress = useCallback(
    async (
      cpf_user,
      cep,
      road,
      district,
      number,
      city,
      state,
      residence_type,
      residence_status,
      corporate_name,
    ) => {
      const response = await api.post<AddressState>('address', {
        cpf_user,
        cep,
        road,
        district,
        number,
        city,
        state,
        residence_type,
        residence_status,
        corporate_name,
      });
    },
    [],
  );

  const setCompany = useCallback(
    async (
      cpf_user,
      cnpj,
      profession,
      branch_of_activity,
      wage,
      company_date_start,
      number_of_employees,
      income_extra,
      income_description,
    ) => {
      const response = await api.post<CompanyState>('company', {
        cpf_user,
        cnpj,
        profession,
        branch_of_activity,
        wage,
        company_date_start,
        number_of_employees,
        income_extra,
        income_description,
      });
    },
    [],
  );

  const updateUser = useCallback(
    async (
      cpf?: string,
      description?: string,
      password?: string,
      first_access?: boolean,
      accept_terms?: boolean,
      total_limit?: string,
      phone_number?: string,
      card_cvv?: string,
      card_number?: string,
      email?: string,
    ) => {
      if (description === 'first_access') {
        await api.put<User>('users', {
          document: cpf,
          first_access,
        });
      }

      if (description === 'accept_terms') {
        await api.put<User>('users', {
          document: cpf,
          accept_terms,
        });
      }

      if (description === 'password') {
        await api.put<User>('users', {
          document: cpf,
          password,
        });
      }

      if (description === 'total_limit') {
        const aux = await api.put<User>('users', {
          document: cpf,
          total_limit,
        });
      }

      if (description === 'phone_number') {
        const aux = await api.put<User>('users', {
          document: cpf,
          phone_number,
        });
      }

      if (description === 'card_cvv') {
        const aux = await api.put<User>('users', {
          document: cpf,
          card_cvv,
        });
      }

      if (description === 'card') {
        const aux = await api.put<User>('users', {
          document: cpf,
          card_number,
          card_cvv,
        });
      }

      if (description === 'email') {
        const aux = await api.put<User>('users', {
          document: cpf,
          email,
        });
      }
    },
    [],
  );

  const createUser = useCallback(
    async ({
      name,
      email,
      password,
      document,
      card_number,
      is_administrative_user,
      is_active,
      total_limit,
      used_limit,
      limit_released,
      access_web,
      first_access,
      accept_terms,
      card_password,
      card_date_validity,
      card_cvv,
      card_active,
      phone_number,
      preferred_name,
      rg,
      gender,
      card_name,
      birth_date,
      nationality,
      number_of_dependents,
      marital_status,
      scholarity,
      mothers_name,
      mailing_address,
      property_size,
      partner_name,
      partner_email,
      user_type,
      person_public,
      know_public_agent,
    }) => {
      const response = await api.post<User>('users', {
        name,
        email,
        password,
        document,
        card_number,
        is_administrative_user,
        is_active,
        total_limit,
        used_limit,
        limit_released,
        access_web,
        first_access,
        accept_terms,
        card_password,
        card_date_validity,
        card_cvv,
        card_active,
        phone_number,
        preferred_name,
        rg,
        gender,
        card_name,
        birth_date,
        nationality,
        number_of_dependents,
        marital_status,
        scholarity,
        mothers_name,
        mailing_address,
        property_size,
        partner_name,
        partner_email,
        user_type,
        person_public,
        know_public_agent,
      });
    },
    [],
  );

  const createUserWeb = useCallback(
    async ({
      name,
      email,
      password,
      document,
      card_number,
      is_active,
      is_administrative_user,
      access_web,
      first_access,
      accept_terms,
      card_password,
      card_cvv,
      card_active,
      user_type,
    }) => {
      const response = await api.post<UserWeb>('users', {
        name,
        email,
        password,
        document,
        card_number,
        is_administrative_user,
        is_active,
        access_web,
        first_access,
        accept_terms,
        card_password,
        card_cvv,
        card_active,
        user_type,
      });
    },
    [],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@Wallet:token');
    localStorage.removeItem('@Wallet:user');

    setData({} as AuthState);
  }, []);

  const ListTransactionsByDate = useCallback(async (startDate, endDate) => {
    const config = {
      headers: {
        Authorization: '6faaba1e62ca15419dca284d0da20d17398ca63b',
        'Content-Type': 'application/json',
      },
    };
    const response = await axios.post<TransactionInfo[]>(
      'https://api.pathbeta.com/sales/date',
      {
        dateStart: startDate,
        dateEnd: endDate,
      },
      config,
    );

    const transactions = response.data;

    return transactions;
  }, []);

  const ListpaymentsByCollaboratorDocument = useCallback(
    async document_collaborator => {
      const config = {
        headers: {
          Authorization: '6faaba1e62ca15419dca284d0da20d17398ca63b',
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.post<IPayment[]>(
        'https://api.pathbeta.com/Payments/collaborator_document',
        {
          document_collaborator,
        },
        config,
      );

      const transactions = response.data;

      return transactions;
    },
    [],
  );

  const ListpaymentsByCollaboratorId = useCallback(async id_collaborator => {
    const config = {
      headers: {
        Authorization: '6faaba1e62ca15419dca284d0da20d17398ca63b',
        'Content-Type': 'application/json',
      },
    };
    const response = await axios.post<IPayment[]>(
      'https://api.pathbeta.com/Payments/collaborator_id',
      {
        id_collaborator,
      },
      config,
    );

    const transactions = response.data;

    return transactions;
  }, []);

  const ListpaymentsById = useCallback(async id => {
    const config = {
      headers: {
        Authorization: '6faaba1e62ca15419dca284d0da20d17398ca63b',
        'Content-Type': 'application/json',
      },
    };
    const response = await axios.post<IPayment>(
      'https://api.pathbeta.com/Payments/id',
      {
        id,
      },
      config,
    );

    const transactions = response.data;

    return transactions;
  }, []);

  const createPayment = useCallback(
    async ({
      amount,
      document_client,
      store,
      id_collaborator,
      name_collaborator,
      document_collaborator,
      sending_method,
      status,
    }) => {
      const response = await api.post<IPayment>('payments', {
        amount,
        document_client,
        store,
        id_collaborator,
        name_collaborator,
        document_collaborator,
        sending_method,
        status,
      });
    },
    [],
  );

  const validatePayment = useCallback(
    async ({
      id,
      amount,
      document_client,
      store,
      id_collaborator,
      name_collaborator,
      document_collaborator,
      sending_method,
      status,
      card_number,
      card_cvv,
      document_input,
    }) => {
      const response = await api.post<IPayment>('payments/validate', {
        id,
        amount,
        document_client,
        store,
        id_collaborator,
        name_collaborator,
        document_collaborator,
        sending_method,
        status,
        card_number,
        card_cvv,
        document_input,
      });
    },
    [],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        createUser,
        createUserWeb,
        createPayment,
        validatePayment,
        updateUser,
        setPhone,
        setAdress,
        setCompany,
        ListTransactionsByDate,
        ListpaymentsByCollaboratorDocument,
        ListpaymentsByCollaboratorId,
        ListpaymentsById,
        searchUserByDocument,
        searchUserByCardNumber,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }

  return context;
}

export { AuthProvider, useAuth };
