/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/function-component-definition */
import Modal from 'react-modal';
import { RiCloseFill } from 'react-icons/ri';
import { Container, HeaderText, ContentInfo, ButtonClose } from './styles';

interface UserInfo {
  name: string;
  email: string;
  document: string;
  card_number: string;
  is_active: boolean;
  total_limit: string;
  used_limit: string;
  card_date_validity: string;
  phone_number: string;
}

interface NewViewModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  user: UserInfo;
}

export function ViewModal({ isOpen, onRequestClose, user }: NewViewModalProps) {
  return (
    <Container>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName="_"
        className="_"
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          content: {
            width: '100%',
            maxWidth: '576px',
            maxHeight: '80%',
            background: '#fff',
            padding: '2rem',
            position: 'relative',
            borderRadius: '0.25rem',
            overflowY: 'auto',
          },
        }}
      >
        <ButtonClose onClick={onRequestClose}>
          <RiCloseFill size={24} color="#bb2124" />
        </ButtonClose>

        <HeaderText>Usuário encontrado:</HeaderText>

        <ContentInfo>
          <h2>Nome:</h2>
          <span>{user.name}</span>
        </ContentInfo>

        <ContentInfo>
          <h2>Documento:</h2>
          <span>{user.document}</span>
        </ContentInfo>

        <ContentInfo>
          <h2>E-mail:</h2>
          <span>{user.email}</span>
        </ContentInfo>

        <ContentInfo>
          <h2>Nº do cartão:</h2>
          <span>{user.card_number}</span>
        </ContentInfo>

        <ContentInfo>
          <h2>Telefone:</h2>
          <span>{user.phone_number}</span>
        </ContentInfo>

        <ContentInfo>
          <h2>Limite liberado:</h2>
          <span>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(parseFloat(user.total_limit))}
          </span>
        </ContentInfo>

        <ContentInfo>
          <h2>Limite utilizado:</h2>
          <span>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(parseFloat(user.used_limit))}
          </span>
        </ContentInfo>

        <ContentInfo>
          <h2>Status de usuário:</h2>
          <span>{user.is_active ? 'Ativo' : 'Inativo'}</span>
        </ContentInfo>
      </Modal>
    </Container>
  );
}
