/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* eslint-disable no-console */
// eslint-disable-next-line no-use-before-define
import React, { useRef, useCallback, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiDollarSign, FiUser, FiHome } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import AuthContext from '../../context/AuthContext';

import logoImg from '../../assets/logo-wallet-pos.svg';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';
import Header from '../../components/HeaderMain';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import { Container, Content, AnimationContainer } from './styles';
import InputSelect from '../../components/InputSelect';

interface CretePaymentFormData {
  amount: string;
  document_client: string;
  store: string;
  id_collaborator: string;
  name_collaborator: string;
  document_collaborator: string;
  sending_method: string;
  status: string;
}

const NewPayment: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const auth = useContext(AuthContext);

  const { createPayment, user, searchUserByDocument } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: CretePaymentFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          amount: Yup.string().required('Valor obrigatório'),
          document_client: Yup.string().required('Documento obrigatório'),
          name_collaborator: Yup.string().required(
            'Nome do atendente obrigatório',
          ),
          sending_method: Yup.string().required('Selecione uma opção válida'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const dataSave = {
          amount: data.amount,
          document_client: data.document_client,
          store: data.store,
          name_collaborator: data.name_collaborator,
          id_collaborator: user.id,
          document_collaborator: user.document,
          sending_method: data.sending_method,
          status: 'CREATED',
        } as unknown as CretePaymentFormData;

        await searchUserByDocument(data.document_client)
          .then(async () => {
            await createPayment(dataSave);
            addToast({
              type: 'success',
              title: 'Pagamento enviado!',
              description: 'Solicitação executada.',
            });
            setTimeout(() => window.location.reload(), 1000);
          })
          .catch(error => {
            addToast({
              type: 'error',
              title: 'Erro na solicitação!',
              description: 'Usuário não encontrado.',
            });
          });

        // history.push('/home');
      } catch (error) {
        console.log('ERROR :: ', error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na solicitação',
          description: 'Verifique os dados e tente novamente.',
        });
      }
    },
    [addToast],
  );

  const options = [
    { value: 'EMAIL', label: 'E-mail' },
    { value: 'WHATSAPP', label: 'WhatsApp' },
    { value: 'TWO_CHANNELS', label: 'E-mail e WhatsApp' },
  ];

  return (
    <>
      {(user.user_type === '1723' || user.user_type === '8077') && (
        <>
          <Header />
          <Container>
            <Content>
              <AnimationContainer>
                <img src={logoImg} alt="wallet logo" />
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <h1>Gerar novo link de pagamento</h1>
                  <h2>(Verifique se os dados estão corretos)</h2>
                  <Input
                    name="amount"
                    type="number"
                    step="0.01"
                    icon={FiDollarSign}
                    placeholder="Valor (R$)"
                  />
                  <Input
                    name="document_client"
                    type="number"
                    icon={FiUser}
                    placeholder="CPF do cliente"
                  />
                  <Input
                    name="store"
                    type="string"
                    icon={FiHome}
                    placeholder="Nome da loja"
                  />
                  <Input
                    name="name_collaborator"
                    type="string"
                    icon={FiUser}
                    placeholder="Responsável"
                  />
                  <div style={{ marginTop: '1rem' }}>
                    <InputSelect
                      name="sending_method"
                      options={options}
                      placeholder="Modo de envio"
                    />
                  </div>
                  <Button type="submit">Gerar link</Button>
                </Form>
              </AnimationContainer>
            </Content>
          </Container>
        </>
      )}
    </>
  );
};

export default NewPayment;
