/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/Auth';

import { Container, Dash } from './styles';

interface TransactionInfo {
  id: string;
  credit_card: string;
  terminal: string;
  transaction_date: string;
  value: string;
  status_sale: number;
}

const OperationsTable = () => {
  const { ListTransactionsByDate } = useAuth();
  const [transactions, setTransactions] = useState<TransactionInfo[]>([]);

  const [dateStartFilter, setDateStartFilter] = useState('');
  const [dateEndFilter, setDateEndFilter] = useState('');
  const [cardNumberFilter, setcardNumberFilter] = useState('');
  const [terminalFilter, setterminalFilter] = useState('');
  const [sumValue, setSumValues] = useState(0);
  const [diffTime, setDiffTime] = useState(0);

  function getDate(date: string) {
    const newDate = new Date(date);

    const day = newDate.getDate().toString().padStart(2, '0');
    const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const year = newDate.getFullYear().toString();
    const hours = newDate.getHours().toString().padStart(2, '0');
    const minutes = newDate.getMinutes().toString().padStart(2, '0');

    return `${day}/${month}/${year} - ${hours}:${minutes}`;
  }

  useEffect(() => {
    async function getTransactions() {
      const start = dateStartFilter || new Date().setUTCHours(0, 0, 0, 0);
      const end = dateEndFilter || new Date().setUTCHours(23, 59, 59, 999);

      const allTransactions = await ListTransactionsByDate(
        new Date(start).toString(),
        new Date(end).toString(),
      );

      const timeDiff = new Date(end).getTime() - new Date(start).getTime();
      const diffInDays = timeDiff / (1000 * 60 * 60 * 24);
      setDiffTime(diffInDays);

      const transactionsSelecteds = [] as TransactionInfo[];
      let totalValue = 0;

      if (cardNumberFilter !== '' && terminalFilter !== '') {
        allTransactions.map(item => {
          if (item.credit_card === cardNumberFilter) {
            if (item.terminal === terminalFilter) {
              transactionsSelecteds.push(item);
              if (item.status_sale === 1) {
                totalValue += parseFloat(item.value);
              }
            }
          }
        });
        setTransactions(transactionsSelecteds as unknown as TransactionInfo[]);
      } else if (cardNumberFilter !== '' && terminalFilter === '') {
        allTransactions.map(item => {
          if (item.credit_card === cardNumberFilter) {
            transactionsSelecteds.push(item);
            if (item.status_sale === 1) {
              totalValue += parseFloat(item.value);
            }
          }
        });
        setTransactions(transactionsSelecteds as unknown as TransactionInfo[]);
      } else if (terminalFilter !== '' && cardNumberFilter === '') {
        allTransactions.map(item => {
          if (item.terminal === terminalFilter) {
            transactionsSelecteds.push(item);
            if (item.status_sale === 1) {
              totalValue += parseFloat(item.value);
            }
          }
        });
        setTransactions(transactionsSelecteds as unknown as TransactionInfo[]);
      } else {
        setTransactions(allTransactions);
        allTransactions.map(item => {
          if (item.status_sale === 1) {
            totalValue += parseFloat(item.value);
          }
        });
      }
      setSumValues(totalValue);
    }
    getTransactions();
  }, [
    dateStartFilter,
    dateEndFilter,
    ListTransactionsByDate,
    cardNumberFilter,
    terminalFilter,
  ]);

  return (
    <Container>
      <h2>Transações</h2>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Dash>
          <h1>Total das transações:</h1>
          <h2>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(sumValue)}
          </h2>
        </Dash>

        <Dash>
          <h1>Período:</h1>
          <h2>
            {Math.round(diffTime) >= 0
              ? Math.round(diffTime) === 0
                ? '1'
                : Math.round(diffTime)
              : '0'}{' '}
            {Math.round(diffTime) <= 1 ? 'dia' : 'dias'}
          </h2>
        </Dash>
      </div>

      <div>
        <input
          className="input-date"
          onChange={text => setDateStartFilter(text.target.value)}
          type="date"
        />
        <input
          className="input-date"
          onChange={text => setDateEndFilter(text.target.value)}
          type="date"
        />
        <input
          className="input-date"
          onChange={text => setcardNumberFilter(text.target.value)}
          placeholder="Número de cartão"
          type="number"
        />
        <input
          className="input-date"
          onChange={text => setterminalFilter(text.target.value)}
          placeholder="Terminal"
          type="text"
        />
        <table>
          <thead>
            <tr>
              <th>Data</th>
              <th>Cartão</th>
              <th>Terminal</th>
              <th>Valor</th>
            </tr>
          </thead>

          <tbody>
            {transactions.map(transaction => (
              <tr key={transaction.id} className="rowBory">
                <td className={transaction.status_sale === 1 ? 'green' : 'red'}>
                  {getDate(transaction.transaction_date)}
                </td>
                <td>{transaction.credit_card}</td>
                <td>{transaction.terminal}</td>
                <td>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(parseFloat(transaction.value))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {transactions.length <= 0 && <h2>Sem transações cadastradas</h2>}
      </div>
    </Container>
  );
};

export default OperationsTable;
