import React from 'react';

import Header from '../../components/HeaderMain';
import Button from '../../components/Button';

import { Container, Description, CardSelect, Justificate } from './styles';

const BlockCard: React.FC = () => {
  return (
    <>
      <Container>
        <Header />

        <Description>
          <h1>
            Para solicitar o cancelamento do seu cartão é necessário detalhar o
            motivo da sua requisição, assim o bloqueio do seu cartão será
            realizado pela nossa equipe.
          </h1>
        </Description>

        <CardSelect>
          <h2>Selecione o cartão a ser bloqueado</h2>
          <div>
            <h1>2307 4491 5122 1467</h1>
          </div>
        </CardSelect>

        <Justificate>
          <h2>Justificativa de bloqueio (mínimo 144 caracteres)</h2>
          <textarea />
          <span>
            <Button type="submit">Enviar</Button>
          </span>
        </Justificate>
      </Container>
    </>
  );
};

export default BlockCard;
