/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
// eslint-disable-next-line no-use-before-define

import React, { useRef, useCallback, useState } from 'react';
import { FiCreditCard, FiMail, FiUser } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Modal from 'react-modal';
import * as Yup from 'yup';

import AuthContext from '../../context/AuthContext';

import logoImg from '../../assets/logo-wallet-pos.svg';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';
import Header from '../../components/HeaderMain';
import { ViewModal } from '../../components/ViewModal';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import InputSelect from '../../components/InputSelect';

import { Container, Content, AnimationContainer } from './styles';

Modal.setAppElement('#root');

interface SearchUserFormData {
  document?: string;
  card_number?: string;
  email?: string;
}

interface UserInfo {
  name: string;
  email: string;
  document: string;
  card_number: string;
  is_active: boolean;
  total_limit: string;
  used_limit: string;
  card_date_validity: string;
  phone_number: string;
}

const ChangeCardAndCvv: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [userSelected, setUserSelected] = useState<UserInfo>({} as UserInfo);

  const { searchUserByDocument, searchUserByCardNumber } = useAuth();
  const { addToast } = useToast();
  const [optionToSearch, setOptionToSearch] = useState('');

  const optionsSearch = [
    { value: '1', label: 'Documento' },
    { value: '2', label: 'Nº cartão' },
  ];

  const handleInputChange = valueItem => {
    setOptionToSearch(valueItem.value);
  };

  function handleOpenViewModal(user: UserInfo) {
    setIsViewModalOpen(true);
    setUserSelected(user);
  }

  function handleCloseViewModal() {
    setIsViewModalOpen(false);
  }

  const handleSubmit = useCallback(
    async (data: SearchUserFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({});

        await schema.validate(data, {
          abortEarly: false,
        });

        if (optionToSearch === '1') {
          const user = await searchUserByDocument(data.document!);
          handleOpenViewModal(user);
        }

        if (optionToSearch === '2') {
          const user = await searchUserByCardNumber(data.card_number!);
          handleOpenViewModal(user);
        }

        if (optionToSearch === '3') {
          await searchUserByDocument(data.document!);
        }

        addToast({
          type: 'success',
          title: 'Usuário encontrado',
          description: 'Solicitação executada.',
        });
      } catch (error) {
        console.log('ERROR :: ', error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na solicitação',
          description: 'Usuário não encontrado!',
        });
      }
    },
    [optionToSearch, addToast, searchUserByDocument, searchUserByCardNumber],
  );

  return (
    <>
      <ViewModal
        isOpen={isViewModalOpen}
        onRequestClose={handleCloseViewModal}
        user={userSelected}
      />
      <Header />
      <Container>
        <Content>
          <AnimationContainer>
            <img src={logoImg} alt="wallet logo" />
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>Procurar usuário</h1>
              <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <InputSelect
                  name="optionSearch"
                  options={optionsSearch}
                  onChange={item => {
                    handleInputChange(item);
                  }}
                  placeholder="Selecione tipo de busca"
                />
              </div>

              {optionToSearch === '1' && (
                <Input
                  name="document"
                  maxLength={4}
                  type="number"
                  icon={FiUser}
                  placeholder="CPF"
                />
              )}

              {optionToSearch === '2' && (
                <Input
                  name="card_number"
                  type="number"
                  icon={FiCreditCard}
                  maxLength={16}
                  placeholder="Nº do cartão"
                />
              )}

              {optionToSearch === '3' && (
                <Input
                  name="email"
                  type="email"
                  icon={FiMail}
                  maxLength={16}
                  placeholder="E-mail"
                />
              )}

              <Button type="submit">Buscar</Button>
            </Form>
          </AnimationContainer>
        </Content>
      </Container>
    </>
  );
};

export default ChangeCardAndCvv;
