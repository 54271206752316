import React from 'react';

import Header from '../../components/HeaderMain';

const UserManagement: React.FC = () => {
  return (
    <>
      <Header />
      <h1>Gerenciamento</h1>
    </>
  );
};

export default UserManagement;
