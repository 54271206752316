/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* eslint-disable no-console */
// eslint-disable-next-line no-use-before-define
import React, {
  useRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FiCreditCard, FiUser } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import AuthContext from '../../context/AuthContext';

import logoImg from '../../assets/logo-wallet-pos.svg';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import {
  Container,
  Content,
  AnimationContainer,
  ContentFail,
  ContentInfo,
} from './styles';

interface CretePaymentFormData {
  id?: string;
  amount: string;
  document_client: string;
  store: string;
  id_collaborator: string;
  name_collaborator: string;
  document_collaborator: string;
  sending_method: string; // EMAIL | WHATSAPP | SMS | TWO_CHANNELS -> EMAIL e WHATSAAPP | THREE_CHANNELS -> EMAIL, WHATSAAPP e SMS
  status: string; // CREATED | PAIDOUT | EXPIRED | CANCELED
  card_number: string;
  card_cvv: string;
  document_input: string; // DOCUMENTO DIGITADO PELO USER
}
interface ParamsData {
  id: string;
}

interface IDataInput {
  card_number: string;
  card_cvv: string;
  input_document: string;
}

interface IPaymentData {
  id: string;
  amount: string;
  document_client: string;
  store: string;
  id_collaborator: string;
  name_collaborator: string;
  document_collaborator: string;
  sending_method: string; // EMAIL | WHATSAPP | SMS | TWO_CHANNELS -> EMAIL e WHATSAAPP | THREE_CHANNELS -> EMAIL, WHATSAAPP e SMS
  status: string; // CREATED | PAIDOUT | EXPIRED | CANCELED
  created: Date;
}

const Payment: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { id } = useParams<ParamsData>();
  const auth = useContext(AuthContext);

  const { addToast } = useToast();

  const { ListpaymentsById, validatePayment } = useAuth();

  const [paymentIsValid, setPaymentIsValid] = useState(false);
  const [paymentFound, setPaymentFound] = useState<IPaymentData>(
    {} as IPaymentData,
  );

  useEffect(() => {
    async function getPayment() {
      const payment = (await ListpaymentsById(id)) as IPaymentData;

      console.log('PAYMENT :: ', payment);

      setPaymentFound(payment);
      if (payment && payment.id) {
        const now: Date = new Date();
        const diffInMinutes: number =
          Math.abs(now.getTime() - new Date(payment.created).getTime()) / 60000;

        if (diffInMinutes <= 10 && paymentFound.status === 'CREATED') {
          setPaymentIsValid(true);
        }
      }
    }
    getPayment();
  }, [ListpaymentsById, id, paymentFound.status, paymentIsValid]);

  const handleSubmit = useCallback(
    async (data: IDataInput) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          card_cvv: Yup.string().required('cvv obrigatório'),
          input_document: Yup.string().required('CPF obrigatório'),
          card_number: Yup.string().required('Selecione uma permissão válida'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const paymentFound2 = (await ListpaymentsById(id)) as IPaymentData;

        const dataSave = {
          id: paymentFound2.id,
          amount: paymentFound2.amount,
          document_client: paymentFound2.document_client,
          store: paymentFound2.store,
          name_collaborator: paymentFound2.name_collaborator,
          id_collaborator: paymentFound2.id_collaborator,
          document_collaborator: paymentFound2.document_collaborator,
          sending_method: paymentFound2.sending_method,
          status: paymentFound2.status,
          created: paymentFound2.created,
          card_number: data.card_number,
          card_cvv: data.card_cvv,
          document_input: data.input_document,
        } as unknown as CretePaymentFormData;

        // const user = await searchUserByDocument(data.document_client);

        const validate = await validatePayment(dataSave);

        // await createUserWeb(dataSave);
        addToast({
          type: 'success',
          title: 'Pagamento realizado!',
          description: 'validate',
        });

        setTimeout(() => window.location.reload(), 1000);

        // history.push('/home');
      } catch (error) {
        console.log('ERROR :: ', error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na solicitação',
          description: 'Verifique as informações e tente novamente.',
        });
      }
    },
    [addToast],
  );

  return (
    <>
      {(paymentIsValid && (
        <>
          <Container>
            <Content>
              <AnimationContainer>
                <img src={logoImg} alt="wallet logo" />
                <h1>Realizar pagamento via link</h1>

                <div>
                  <ContentInfo>
                    <h2>Local:</h2>
                    <span>{paymentFound.store}</span>
                  </ContentInfo>

                  <ContentInfo>
                    <h2>Atendente:</h2>
                    <span>{paymentFound.name_collaborator}</span>
                  </ContentInfo>

                  <ContentInfo>
                    <h2>Valor:</h2>
                    <span>{paymentFound.amount}</span>
                  </ContentInfo>
                </div>

                <Form ref={formRef} onSubmit={handleSubmit}>
                  <Input
                    name="card_number"
                    type="number"
                    icon={FiCreditCard}
                    maxLength={16}
                    placeholder="Nº do cartão"
                  />
                  <Input
                    name="card_cvv"
                    type="number"
                    icon={FiCreditCard}
                    maxLength={16}
                    placeholder="Nº do cvv"
                  />
                  <Input
                    name="input_document"
                    type="number"
                    icon={FiUser}
                    placeholder="CPF"
                  />
                  <Button type="submit">Realizar pagamento</Button>
                </Form>
              </AnimationContainer>
            </Content>
          </Container>
        </>
      )) || (
        <>
          <Container>
            <Content>
              <AnimationContainer>
                {(paymentFound.status === 'PAIDOUT' && (
                  <ContentFail>
                    <img src={logoImg} alt="wallet logo" />
                    <h1>Obrigado pelo pagamento!</h1>
                  </ContentFail>
                )) || (
                  <ContentFail>
                    <img src={logoImg} alt="wallet logo" />

                    <h1>
                      Link de pagamento não existe, expirou ou já foi pago!.
                    </h1>

                    <h2>
                      O tempo máximo que o link fica disponível é de 10 minutos!
                    </h2>
                  </ContentFail>
                )}
              </AnimationContainer>
            </Content>
          </Container>
        </>
      )}
    </>
  );
};

export default Payment;
