import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import AppProvider from './hooks';
import Routes from './routes';
import AuthContext from './context/AuthContext';

import { GlobalStyle } from './styles/global';
import HeaderMain from './components/HeaderMain';

const App: React.FC = () => (
  <Router>
    <AuthContext.Provider value={{ name: 'user' }}>
      <AppProvider>
        <Routes />
      </AppProvider>
    </AuthContext.Provider>
    <GlobalStyle />
  </Router>
);

export default App;
