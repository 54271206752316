/* eslint-disable camelcase */
/* eslint-disable no-console */
// eslint-disable-next-line no-use-before-define
import React from 'react';
import { useHistory } from 'react-router-dom';

import Header from '../../components/HeaderMain';

import { useAuth } from '../../hooks/Auth';

import { Container } from './styles';
import OperationsTable from '../../components/OperationsTable';

const Financial: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();

  return (
    <>
      {(user.user_type === '1723' || user.user_type === '8077') && (
        <>
          <Header />
          <Container>
            <OperationsTable />
          </Container>
        </>
      )}

      {user.user_type !== '1723' && user.user_type !== '8077' && (
        <div style={{ alignItems: 'center', justifyContent: 'center' }}>
          <h2>Página inexistente!</h2>
        </div>
      )}
    </>
  );
};

export default Financial;
