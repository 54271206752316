import styled from 'styled-components';

export const Container = styled.div`
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }
  &.react-modal-overlay {
    background: rgba(0, 0, 0, 1);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.react-modal-content {
    width: 100%;
    max-width: 576px;
    background: #fff;
    padding: 3rem;
    position: relative;
    border-radius: 0.25rem;

    h1 {
      font-size: 55px;
    }
  }

  h1 {
    background: #000;
  }

  &.item-info {
    background-color: #000;
    padding: 5rem;
    h1 {
      background: #000;
    }
  }
`;

export const HeaderText = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  align-self: center;
`;

export const ContentInfo = styled.h1`
  display: flex;
  flex-direction: row;
  background-color: #f6f7fa;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin-bottom: 0.25rem;

  h2 {
    font-size: 16px;
    font-weight: 500;
  }

  span {
    font-size: 16px;
    font-weight: 400;
    margin-left: 5px;
  }
`;

export const ButtonClose = styled.button`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  border: 0;
  background: transparent;

  svg {
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.6);
    }
  }
`;
