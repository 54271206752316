/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  display: flex;
  padding: 10px;
  min-width: 12rem;
  border: 0;
  height: 6rem;
  align-items: flex-start;
  background: #fff;
  margin-top: 30px;
  margin-left: 30px;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  box-shadow: 1px 2px 9px #000;
  flex-direction: column;

  &:hover {
    background: ${shade(0.1, '#fff')};
  }
`;

export const TextDescription = styled.text`
  font-size: 1.2rem;
  color: #000;
  margin-left: 0.5rem;
  font-weight: 500;
`;

export const Image = styled.img`
  width: 100px;
`;
