/* eslint-disable func-names */
/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
/* eslint-disable no-console */
// eslint-disable-next-line no-use-before-define
import React, { useRef, useCallback, useContext } from 'react';
import { FiTerminal } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import logoImg from '../../assets/logo-wallet-pos.svg';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';
import Header from '../../components/HeaderMain';

import { useToast } from '../../hooks/Toast';

import { Container, Content, AnimationContainer } from './styles';

interface NumberOfCardsFormData {
  numberOfCards: number;
}

interface CsvFormData {
  card: string;
  cvv: string;
}

const CardGenerate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: NumberOfCardsFormData) => {
      const cardObject = { card: '', cvv: '' };

      const generateCsv = (numberOfCards: number) => {
        const cards: CsvFormData[] = [];
        let cardnumber = '';

        for (let i = 1; i <= numberOfCards; i++) {
          for (let j = 1; j <= 16; j++) {
            const aux = Math.floor(Math.random() * 10);
            cardnumber += aux;
          }

          const cardOb: CsvFormData = Object.create(cardObject);

          cardOb.card = cardnumber;
          cardOb.cvv = (Math.floor(Math.random() * 898) + 101).toString();

          cards.push(cardOb);
          cardnumber = '';
        }

        let csv = 'card, cvv\n';

        cards.forEach(function (row) {
          csv += row.card;
          csv += `,${row.cvv}`;
          csv += '\n';
        });

        const hiddenElement = document.createElement('a');
        hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
        hiddenElement.target = '_blank';
        hiddenElement.download = `cartoes-${Date.now()} .csv`;
        hiddenElement.click();
      };
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          numberOfCards: Yup.string().required(
            'Digite a quantidade de cartões!',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        generateCsv(data.numberOfCards);

        addToast({
          type: 'success',
          title: 'Cartões gerados',
          description: 'Download iniciado.',
        });
      } catch (error) {
        console.log('ERROR :: ', error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro ao gerar cartões',
          description: 'Tente novamente.',
        });
      }
    },
    [addToast],
  );

  return (
    <>
      <Header />
      <Container>
        <Content>
          <AnimationContainer>
            <img src={logoImg} alt="wallet logo" />

            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>Gerar novos cartões</h1>

              <Input
                name="numberOfCards"
                type="number"
                icon={FiTerminal}
                placeholder="Número de cartões"
              />
              <Button type="submit">Gerar cartões</Button>
            </Form>
          </AnimationContainer>
        </Content>
      </Container>
    </>
  );
};

export default CardGenerate;
