import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  form {
    margin: 2.5rem 0rem;
    width: 100%;
    flex-direction: column;

    div {
      flex-direction: row;
    }
  }
`;
