/* eslint-disable no-alert */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-expressions */
import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FiPhoneCall, FiUser, FiBookmark, FiGrid } from 'react-icons/fi';

import getValidationErrors from '../../utils/getValidationErrors';

import Header from '../../components/HeaderMain';
import Input from '../../components/Input';
import InputSelect from '../../components/InputSelect';
import Button from '../../components/Button';
import logoImg from '../../assets/logo-wallet-pos.svg';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import { Container, Content, AnimationContainer } from './styles';

interface UserFormData {
  id: string;
  name: string;
  email: string;
  document: string;
  password: string;
  card_number: string;
  is_administrative_user: boolean;
  is_active: boolean;
  total_limit: string;
  used_limit: string;
  limit_released: string;
  access_web: boolean;
  first_access: boolean;
  accept_terms: boolean;
  card_password: string;
  card_date_validity: string;
  card_cvv: string;
  card_active: boolean;
  phone_number: string;
  phone_number_residence: string;
  phone_number_commercial: string;
  preferred_name: string;
  rg: string;
  gender: string;
  card_name: string;
  birth_date: Date;
  nationality: string;
  number_of_dependents: string;
  marital_status: string;
  scholarity: string;
  mothers_name: string;
  mailing_address: string;
  property_size: string;
  partner_name: string;
  partner_email: string;
  user_type: string;
  person_public: boolean;
  know_public_agent: boolean;
  cep: string;
  road: string;
  district: string;
  number: string;
  city: string;
  state: string;
  residence_type: string;
  residence_status: string;
  cep_commercial: string;
  road_commercial: string;
  district_commercial: string;
  number_commercial: string;
  city_commercial: string;
  state_commercial: string;
  commercial_type: string;
  commercial_status: string;
  corporate_name: string;
  cnpj: string;
  profession: string;
  branch_of_activity: string;
  wage: string;
  company_date_start: string;
  number_of_employees: string;
  income_extra: string;
  income_description: string;
}

const DashboardUser: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const [marital, setMarital] = useState();
  const [statusWage, setStatusWage] = useState('');
  const [statusIncome, setStatusIncome] = useState('');
  const [otherIncome, setOtherIncome] = useState(false);
  const [personPublic, setPersonPublic] = useState(false);
  const [knowPublicAgent, setKnowPublicAgent] = useState(false);

  const { setPhone, createUser, setAdress, setCompany } = useAuth();
  const { addToast } = useToast();

  const handleInputChange = valueItem => {
    setStatusWage(valueItem.target.value);
  };

  const handleInputChange2 = valueItem => {
    setStatusIncome(valueItem.target.value);
  };

  const handleCalculateTotalWage = () => {
    let totalValue = 0;

    if (statusWage != null) {
      totalValue += parseFloat(statusWage) || 0;
    }

    if (statusIncome != null) {
      totalValue += parseFloat(statusIncome) || 0;
    }

    const value = totalValue.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });

    return value;
  };

  const handleSubmit = useCallback(
    async (data: UserFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          preferred_name: Yup.string().required('Campo obrigatório'),
          document: Yup.string().required('CPF obrigatório'),
          rg: Yup.string().required('RG obrigatório'),
          gender: Yup.string().required('Sexo obrigatório'),
          card_name: Yup.string().required('Nome obrigatório'),
          birth_date: Yup.string().required('Data de nascimento obrigatória'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          nationality: Yup.string().required('Nacionalidade obrigatória'),
          number_of_dependents: Yup.string().required(
            'Número de dependentes obrigatório',
          ),
          mothers_name: Yup.string().required('Nome da mãe obrigatório'),
          marital_status: Yup.string().required('Estado civil obrigatório'),
          scholarity: Yup.string().required(
            'Nível de escolaridade obrigatório',
          ),
          cep: Yup.string().required('CEP obrigatório'),
          road: Yup.string().required('Logradouro obrigatório'),
          district: Yup.string().required('Bairro obrigatório'),
          number: Yup.string().required('Número obrigatório'),
          city: Yup.string().required('Cidade obrigatório'),
          state: Yup.string().required('Estado obrigatório'),
          residence_type: Yup.string().required(
            'Tipo de residência obrigatório',
          ),
          residence_status: Yup.string().required(
            'Status da residência obrigatório',
          ),
          mailing_address: Yup.string().required('Campo obrigatório'),
          phone_number_residence: Yup.string().required('Telefone obrigatório'),
          phone_number_commercial: Yup.string().required(
            'Telefone obrigatório',
          ),
          phone_number: Yup.string().required('Telefone obrigatório'),
          property_size: Yup.string().required('Telefone obrigatório'),

          company: Yup.string().required('Campo obrigatório'),
          cnpj: Yup.string().required('Campo obrigatório'),
          profession: Yup.string().required('Campo obrigatório'),
          branch_of_activity: Yup.string().required('Campo obrigatório'),
          wage: Yup.string().required('Campo obrigatório'),
          company_date_start: Yup.string().required('Campo obrigatório'),
          number_of_employees: Yup.string().required('Campo obrigatório'),

          corporate_name: Yup.string().required('Razão Social obrigatório'),
          cep_commercial: Yup.string().required('CEP obrigatório'),
          road_commercial: Yup.string().required('Logradouro obrigatório'),
          district_commercial: Yup.string().required('Bairro obrigatório'),
          number_commercial: Yup.string().required('Número obrigatório'),
          city_commercial: Yup.string().required('Cidade obrigatório'),
          state_commercial: Yup.string().required('Estado obrigatório'),
          commercial_type: Yup.string().required(
            'Tipo de residência obrigatório',
          ),
          commercial_status: Yup.string().required(
            'Status da residência obrigatório',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const dataSave = {
          name: data.name,
          email: data.email,
          document: data.document,
          password: 'knjvdbgvrbyu',
          card_number: '5678000000002121',
          is_administrative_user: false,
          is_active: false,
          total_limit: '0',
          used_limit: '0',
          limit_released: data.limit_released,
          access_web: false,
          first_access: false,
          accept_terms: false,
          card_password: '8754',
          card_date_validity: '0930',
          card_cvv: '901',
          card_active: true,
          phone_number: data.phone_number,
          preferred_name: data.preferred_name,
          rg: data.rg,
          gender: data.gender,
          card_name: data.card_name,
          birth_date: data.birth_date,
          nationality: data.nationality,
          number_of_dependents: data.number_of_dependents,
          marital_status: data.marital_status,
          scholarity: data.scholarity,
          mothers_name: data.mothers_name,
          mailing_address: data.mailing_address,
          property_size: data.property_size,
          partner_name: data.partner_name,
          partner_email: data.partner_email,
          user_type: '1980',
          person_public: personPublic,
          know_public_agent: knowPublicAgent,
        } as unknown as UserFormData;

        await createUser(dataSave);

        await setPhone(data.document, data.phone_number_residence, 'residence');
        await setPhone(
          data.document,
          data.phone_number_commercial,
          'commercial',
        );
        await setPhone(data.document, data.phone_number, 'cell');

        await setAdress(
          data.document,
          data.cep,
          data.road,
          data.district,
          data.number,
          data.city,
          data.state,
          data.residence_type,
          data.residence_status,
          '',
        );

        await setAdress(
          data.document,
          data.cep_commercial,
          data.road_commercial,
          data.district_commercial,
          data.number_commercial,
          data.city_commercial,
          data.state_commercial,
          data.commercial_type,
          data.commercial_status,
          data.corporate_name,
        );

        await setCompany(
          data.document,
          data.cnpj,
          data.profession,
          data.branch_of_activity,
          data.wage,
          data.company_date_start,
          data.number_of_employees,
          data.income_extra ? data.income_extra : '0',
          data.income_description ? data.income_description : ' ',
        );

        window.scrollTo(0, 0);

        addToast({
          type: 'success',
          title: 'Solicitação efetuada',
          description: 'Solicitação em analise.',
        });
        alert('Solicitação realizada com sucesso');
        history.push('/home');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          alert('Algumas informações obrigatórias não foram preenchidas');
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na solicitação',
          description: 'Verifique as informações e tente novamente.',
        });
      }
    },
    [
      addToast,
      createUser,
      history,
      knowPublicAgent,
      personPublic,
      setAdress,
      setCompany,
      setPhone,
    ],
  );

  const optionsGender = [
    { value: '1', label: 'Masculino' },
    { value: '2', label: 'Feminino' },
    { value: '3', label: 'Outro' },
    { value: '4', label: 'Não desejo informar' },
  ];

  const optionsMarital = [
    { value: '1', label: 'Solteiro (a)' },
    { value: '2', label: 'Casado (a)' },
    { value: '3', label: 'Divorciado (a)' },
    { value: '4', label: 'Viuvo (a)' },
  ];

  const optionsMailing = [
    { value: '1', label: 'Residencial' },
    { value: '2', label: 'Comercial' },
  ];

  const optionsScholarity = [
    { value: '1', label: 'Primário completo' },
    { value: '2', label: 'Primário incompleto' },
    { value: '3', label: 'Secundário completo' },
    { value: '4', label: 'Segundário incompleto' },
    { value: '5', label: 'Superior completo' },
    { value: '6', label: 'Superior incompleto' },
    { value: '7', label: 'Pós-graduação' },
    { value: '8', label: 'Mestrado' },
    { value: '9', label: 'Doutorado' },
  ];

  return (
    <>
      <Header />
      <Container>
        <Content>
          <AnimationContainer>
            <img src={logoImg} alt="wallet logo" />
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>
                Preencha as informações abaixo para a solicitação de um novo
                cartão
              </h1>

              <h2>Dados pessoais</h2>
              <Input name="name" icon={FiUser} placeholder="Nome completo" />
              <Input
                name="preferred_name"
                icon={FiUser}
                placeholder="Como quer ser chamado (a)"
              />
              <Input
                name="document"
                type="number"
                icon={FiUser}
                placeholder="CPF"
              />
              <Input name="rg" icon={FiUser} placeholder="RG" />
              <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <InputSelect
                  name="gender"
                  options={optionsGender}
                  placeholder="Selecione o sexo"
                />
              </div>
              <Input
                name="card_name"
                maxLength={27}
                icon={FiUser}
                placeholder="Nome no cartão (Max. 27)"
              />
              <h4 style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                Data de nascimento:
              </h4>
              <Input
                name="birth_date"
                type="date"
                icon={FiUser}
                placeholder="Data de nascimento"
              />
              <Input name="email" icon={FiUser} placeholder="E-mail" />
              <Input
                name="nationality"
                icon={FiUser}
                placeholder="Nacionalidade"
              />
              <Input
                name="number_of_dependents"
                type="number"
                icon={FiUser}
                placeholder="Nº de dependentes (menores de 18 anos)"
              />
              <Input
                name="mothers_name"
                icon={FiUser}
                placeholder="Nome da mãe"
              />

              <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <InputSelect
                  name="marital_status"
                  options={optionsMarital}
                  placeholder="Estado civil"
                  onChange={option => setMarital(option?.value)}
                />
              </div>

              {marital === '2' && (
                <>
                  <Input
                    name="partner_name"
                    icon={FiUser}
                    placeholder="Nome do cônjuge"
                  />
                  <Input
                    name="partner_email"
                    icon={FiUser}
                    placeholder="E-mail do cônjuge"
                  />
                </>
              )}

              <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <InputSelect
                  name="scholarity"
                  options={optionsScholarity}
                  placeholder="Nível de escolaridade"
                />
              </div>

              <h2>Endereço residencial</h2>
              <Input
                name="cep"
                type="number"
                icon={FiBookmark}
                placeholder="CEP"
              />
              <Input name="road" icon={FiBookmark} placeholder="Logradouro" />
              <Input name="district" icon={FiBookmark} placeholder="Bairro" />
              <Input
                name="number"
                type="number"
                icon={FiBookmark}
                placeholder="Número"
              />
              <Input name="city" icon={FiBookmark} placeholder="Cidade" />
              <Input
                name="state"
                maxLength={2}
                icon={FiBookmark}
                placeholder="UF"
              />
              <Input
                name="residence_type"
                icon={FiBookmark}
                placeholder="Tipo de residência"
              />
              <Input
                name="residence_status"
                icon={FiBookmark}
                placeholder="Status de residência"
              />

              <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <InputSelect
                  name="mailing_address"
                  options={optionsMailing}
                  placeholder="Endereço de correspondência"
                />
              </div>

              <h2>Dados de telefone</h2>
              <Input
                name="phone_number_residence"
                type="number"
                icon={FiPhoneCall}
                placeholder="Telefone residencial"
              />
              <Input
                name="phone_number_commercial"
                type="number"
                icon={FiPhoneCall}
                placeholder="Telefone comercial"
              />
              <Input
                name="phone_number"
                type="number"
                icon={FiPhoneCall}
                placeholder="Telefone celular"
              />

              <h2>Tamanho da propriedade</h2>
              <Input
                name="property_size"
                icon={FiBookmark}
                placeholder="Em hectares"
              />

              <h2>Dados comerciais</h2>
              <Input name="company" icon={FiGrid} placeholder="Empresa atual" />
              <Input
                name="cnpj"
                type="number"
                icon={FiGrid}
                placeholder="CNPJ"
              />
              <Input name="profession" icon={FiGrid} placeholder="Profissão" />

              <Input
                name="branch_of_activity"
                icon={FiGrid}
                placeholder="Ramo da atividade"
              />

              <Input
                name="wage"
                type="number"
                icon={FiGrid}
                placeholder="Salário (R$)"
                value={statusWage}
                onChange={item => {
                  handleInputChange(item);
                }}
              />

              <Input
                name="company_date_start"
                icon={FiGrid}
                placeholder="Tempo de empresa"
              />

              <Input
                name="number_of_employees"
                icon={FiGrid}
                placeholder="Quantidade de funcionários"
              />

              <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <input
                  type="checkbox"
                  name="correspondence"
                  onChange={() => {
                    setOtherIncome(!otherIncome);
                  }}
                />{' '}
                Possui outras rendas?
              </div>
              {otherIncome && (
                <>
                  <Input
                    type="number"
                    name="income_extra"
                    icon={FiGrid}
                    placeholder="Outras rendas (R$)"
                    value={statusIncome}
                    onChange={item => {
                      handleInputChange2(item);
                    }}
                  />

                  <Input
                    name="income_description"
                    icon={FiGrid}
                    placeholder="Descrição de outras rendas"
                  />
                </>
              )}

              {statusWage != null && (
                <h4 style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                  Renda total: {handleCalculateTotalWage()}
                </h4>
              )}

              <h2>Endereço comercial (Propriedade rural)</h2>
              <Input
                name="corporate_name"
                icon={FiBookmark}
                placeholder="Razão social"
              />
              <Input
                name="cep_commercial"
                type="number"
                icon={FiBookmark}
                placeholder="CEP"
              />
              <Input
                name="road_commercial"
                icon={FiBookmark}
                placeholder="Logradouro"
              />
              <Input
                name="district_commercial"
                icon={FiBookmark}
                placeholder="Bairro"
              />
              <Input
                name="number_commercial"
                type="number"
                icon={FiBookmark}
                placeholder="Número"
              />
              <Input
                name="city_commercial"
                icon={FiBookmark}
                placeholder="Cidade"
              />
              <Input
                name="state_commercial"
                maxLength={2}
                icon={FiBookmark}
                placeholder="UF"
              />
              <Input
                name="commercial_type"
                icon={FiBookmark}
                placeholder="Tipo de residência"
              />
              <Input
                name="commercial_status"
                icon={FiBookmark}
                placeholder="Status de residência"
              />

              <div
                style={{
                  marginBottom: '1rem',
                  marginTop: '1rem',
                }}
              >
                <input
                  type="checkbox"
                  name="correspondence"
                  onChange={() => {
                    setPersonPublic(!personPublic);
                  }}
                />{' '}
                Exerce ou exerceu nos últimos cinco anos algum cargo, emprego ou
                função pública relevante?
              </div>
              <div
                style={{
                  marginBottom: '1rem',
                  marginTop: '1rem',
                }}
              >
                <input
                  type="checkbox"
                  name="correspondence"
                  onChange={() => {
                    setKnowPublicAgent(!knowPublicAgent);
                  }}
                />{' '}
                Possui Relacionamento/Ligação com Agente Público?
              </div>

              <Button type="submit">Enviar solicitação</Button>
            </Form>
          </AnimationContainer>
        </Content>
      </Container>
    </>
  );
};

export default DashboardUser;
