import React from 'react';

import Header from '../../components/HeaderMain';
import Button from '../../components/Button';

import { Container, Description, CardSelect, Justificate } from './styles';

const ContactUs: React.FC = () => {
  return (
    <>
      <Container>
        <Header />

        <Description>
          <h1>Está precisando de ajuda?</h1>
          <h1>
            Preencha os campos abaixo para solicitar o apoio da nossa equipe.
          </h1>
        </Description>

        <Justificate>
          <h2>Assunto</h2>
          <textarea style={{ height: '40px' }} />
          <h2>Motivo do contato</h2>
          <textarea />
          <span>
            <Button type="submit">Enviar</Button>
          </span>
        </Justificate>
      </Container>
    </>
  );
};

export default ContactUs;
