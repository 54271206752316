import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import ForgotPassword from '../pages/ForgotPassword';
import SolicitNewCard from '../pages/SolicitNewCard';

import Dashboard from '../pages/DashboardUser';
import BlockCard from '../pages/BlockCard';
import ContactUs from '../pages/ContactUs';

import Main from '../pages/Main';

import DashboardAdmin from '../pages/DashboardAdmin';
import UserEdition from '../pages/UserEdition';
import UserManagement from '../pages/UserManagement';
import CardGenerate from '../pages/CardGenerate';
import ChangeLimit from '../pages/ChangeLimit';
import ChangePhone from '../pages/ChangePhone';
import ChangeEmail from '../pages/ChangeEmail';
import ChangeCardAndCvv from '../pages/ChangeCardAndCvv';
import SearchUser from '../pages/SearchUser';
import CreateUserWeb from '../pages/CreateUserWeb';
import Financial from '../pages/Financial';
import NewPayment from '../pages/NewPayment';
import Payment from '../pages/Payment';
import FinancialPayment from '../pages/FinancialPayment';

import { useAuth } from '../hooks/Auth';

const Routes: React.FC = () => {
  const { signIn } = useAuth();
  const user = localStorage.getItem('@Wallet:user');
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/newcard" exact component={SolicitNewCard} />
      <Route path="/forgotpassword" exact component={ForgotPassword} />
      <Route path="/payment/:id" exact component={Payment} isPrivate />

      <Route path="/home" exact component={Main} isPrivate />
      <Route path="/dashboard" exact component={Dashboard} isPrivate />
      <Route path="/changelimit" exact component={ChangeLimit} isPrivate />
      <Route path="/changephone" exact component={ChangePhone} isPrivate />
      <Route path="/changeemail" exact component={ChangeEmail} isPrivate />
      <Route path="/changecard" exact component={ChangeCardAndCvv} isPrivate />
      <Route path="/searchuser" exact component={SearchUser} isPrivate />
      <Route path="/createuserweb" exact component={CreateUserWeb} isPrivate />
      <Route
        path="/financial_payment"
        exact
        component={FinancialPayment}
        isPrivate
      />
      <Route
        path="/dashboardadmin"
        exact
        component={DashboardAdmin}
        isPrivate
      />
      <Route path="/blockcard" exact component={BlockCard} isPrivate />
      <Route path="/contactus" exact component={ContactUs} isPrivate />

      {/* <Route path="/adminwallet" exact component={DashboardAdmin} isPrivate /> */}
      <Route path="/useredit" exact component={UserEdition} isPrivate />
      <Route path="/cardgenerate" exact component={CardGenerate} isPrivate />
      <Route
        path="/usermanagement"
        exact
        component={UserManagement}
        isPrivate
      />
      <Route path="/financial" exact component={Financial} isPrivate />
      <Route path="/newpayment" exact component={NewPayment} isPrivate />
    </Switch>
  );
};

export default Routes;
