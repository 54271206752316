import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 37.5rem;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromLeft} 1s;

  img {
    margin-bottom: -2rem;
    @media screen and (max-width: 768px) {
      visibility: hidden;
    }
  }

  form {
    margin: 2.5rem 0rem;
    width: 21.25rem;
    text-align: center;

    h1 {
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
      align-self: initial;
    }

    h2 {
      font-size: 1rem;
      margin-bottom: 1.5rem;
      margin-top: 1.5rem;
    }

    a {
      color: #28262e;
      display: block;
      margin-top: 1.5rem;
      text-decoration: none;
      transition: color 0.2s;

      svg {
        margin-right: 1rem;
      }

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }
  }

  > a {
    color: #28262e;
    display: block;
    text-decoration: none;
    transition: color 0.2s;
    margin-top: --2rem;

    display: flex;
    align-items: center;

    svg {
      margin-right: 0.5rem;
    }

    &:hover {
      color: ${shade(0.2, '#f4ede8')};
    }
  }
`;

export const ContentButon = styled.div`
  display: flex;
  align-self: center;
  flex-direction: row;
  margin-top: 100px;
  padding: 14px;
  width: 800px;
  max-height: 40px;
  background: #8ac5dd;
  border-radius: 8px;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h2 {
    font-size: 16px;
  }

  h3 {
    font-size: 14px;
  }

  svg {
    align-self: center;
  }
`;

export const ItemList = styled.div`
  display: flex;
  align-self: center;
  flex-direction: row;
  width: 780px;
  padding: 5px;
  border-bottom: 1px solid #000;
`;

export const TextItemList = styled.h3`
  width: 30%;
  font-size: 14px;
  max-height: 30px;
  color: #000;
  margin-right: 10px;
`;
