import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  div {
    input {
      margin-right: 15px;
      margin-bottom: 15px;
      padding: 5px;
      border-radius: 8px;
    }
  }

  h2 {
    margin-bottom: 15px;
  }

  h3 {
    margin-bottom: 15px;
    color: #8ac5dd;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 0.3rem;

    th {
      color: #fff;
      background-color: #28262e;
      font-weight: 400;
      padding: 0.3rem 2rem;
      text-align: left;
      line-height: 1.5rem;

      &:first-child {
        border-radius: 10px 0 0 0;
      }

      &:last-child {
        border-radius: 0 10px 0 0;
      }
    }

    tr {
      transition: filter 0.2s;
      &.rowBory {
        &:hover {
          cursor: pointer;
          filter: opacity(0.8);
        }
      }
    }

    td {
      padding: 0.3rem 2rem;
      border: 0;
      background: #f2f2f2;
      color: #000;
      white-space: nowrap;

      &:first-child {
        border-radius: 10px 0 0 10px;
      }

      &:last-child {
        border-radius: 0 10px 10px 0;
      }

      &.green {
        border-left: 8px solid #8ac5dd;
      }

      &.red {
        border-left: 8px solid #ff0000;
      }

      &.Config {
        border-left: 8px solid #f0ad4e;
      }
    }
  }
`;

export const Dash = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80px;
  width: 200px;
  margin-bottom: 15px;
  border-radius: 8px;
  border-top: 8px solid #8ac5dd;
  background-color: #f2f2f2;
  box-shadow: 1px 2px 4px #000;
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }

  h1 {
    font-size: 16px;
  }
  h2 {
    font-size: 20px;
    margin-top: 5px;
  }
`;
