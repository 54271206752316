/* eslint-disable no-console */
// eslint-disable-next-line no-use-before-define
import React, { useRef, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiUser, FiMail, FiLock, FiCreditCard, FiFile } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';
import Header from '../../components/HeaderMain';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import { Container, AnimationContainer, Checkbox } from './styles';

interface CreateUserFormData {
  name: string;
  email: string;
  password: string;
  document: string;
  cardNumber: string;
  isAdministrativeUser: boolean;
  isActive: boolean;
}

const UserEdit: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const { createUser } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: CreateUserFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().required('Senha obrigatória'),
          name: Yup.string().required('Nome obrigatório'),
          document: Yup.string().required('Documento obrigatório'),
          cardNumber: Yup.string().required('Número do cartão obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        // await createUser({
        //   name: data.name,
        //   email: data.email,
        //   password: data.password,
        //   document: data.document,
        //   card_number: data.cardNumber,
        //   is_administrative_user: data.isAdministrativeUser,
        //   is_active: data.isActive,
        //   user_type: '1980',
        //   access_web: true,
        // });

        history.push('/dashboard');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            'Ocorreu um erro durante a autenticação, verifique as credenciais.',
        });
      }
    },
    [addToast, history],
  );

  const handleChange = (e: { target: { checked?: boolean } }) => {
    const { checked } = e.target;
  };

  return (
    <Container>
      <Header />
      <AnimationContainer>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>Cadastro de usuários</h1>
          <Input name="name" icon={FiUser} placeholder="Nome completo" />
          <Input name="document" icon={FiFile} placeholder="Documento" />
          <Input
            name="cardNumber"
            icon={FiCreditCard}
            placeholder="Número do cartão"
          />

          <Input name="email" icon={FiMail} placeholder="E-mail" />
          <Input
            name="password"
            icon={FiLock}
            type="password"
            placeholder="Senha"
          />
          <Checkbox>
            <input
              name="checkBoxIsAdm"
              type="checkbox"
              onChange={handleChange}
            />
            <h2>É administrador</h2>
          </Checkbox>
          <Button type="submit">Entrar</Button>
        </Form>
      </AnimationContainer>
    </Container>
  );
};

export default UserEdit;
