/* eslint-disable camelcase */
/* eslint-disable no-console */
// eslint-disable-next-line no-use-before-define
import React, { useRef, useCallback, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiCreditCard, FiLock, FiMail, FiUser } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import AuthContext from '../../context/AuthContext';

import logoImg from '../../assets/logo-wallet-pos.svg';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';
import Header from '../../components/HeaderMain';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import { Container, Content, AnimationContainer } from './styles';
import InputSelect from '../../components/InputSelect';

interface CreteUserFormData {
  name: string;
  email: string;
  password: string;
  document: string;
  card_number: string;
  is_active: boolean;
  is_administrative_user: boolean;
  access_web: boolean;
  first_access: boolean;
  accept_terms: boolean;
  card_password: string;
  card_cvv: string;
  card_active: boolean;
  user_type: string;
  permissions: string;
}

const CreateUserWeb: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const auth = useContext(AuthContext);

  const { createUserWeb, user } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: CreteUserFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().required('Senha obrigatória'),
          document: Yup.string().required('CPF obrigatório'),
          permissions: Yup.string().required('Selecione uma permissão válida'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const dataSave = {
          name: data.name,
          email: data.email,
          password: data.password,
          document: data.document,
          card_number: '5678000000002121',
          is_active: true,
          is_administrative_user: false,
          access_web: true,
          first_access: false,
          accept_terms: false,
          card_password: '',
          card_cvv: '',
          card_active: false,
          user_type: data.permissions,
        } as unknown as CreteUserFormData;

        await createUserWeb(dataSave);
        addToast({
          type: 'success',
          title: 'Usuário criado!',
          description: 'Solicitação executada.',
        });

        // history.push('/home');
      } catch (error) {
        console.log('ERROR :: ', error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na solicitação',
          description:
            'Verifique se o usuário ainda não existe e tente novamente.',
        });
      }
    },
    [createUserWeb, addToast],
  );

  const options = [
    { value: '3701', label: 'Apenas novas solicitações' },
    { value: '2814', label: 'Novas solicitações e alterar limite' },
    // { value: '1723', label: 'Administrador' },
  ];

  return (
    <>
      {(user.user_type === '1723' ||
        user.user_type === '8077' ||
        user.user_type === '1213') && (
        <>
          <Header />
          <Container>
            <Content>
              <AnimationContainer>
                <img src={logoImg} alt="wallet logo" />
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <h1>Novo usuário Web</h1>
                  <Input name="name" icon={FiUser} placeholder="Nome" />
                  <Input
                    name="email"
                    type="email"
                    icon={FiMail}
                    placeholder="E-mail"
                  />
                  <Input
                    name="password"
                    icon={FiLock}
                    type="password"
                    placeholder="Senha"
                  />
                  <Input
                    name="document"
                    type="number"
                    icon={FiUser}
                    placeholder="CPF"
                  />
                  <div style={{ marginTop: '1rem' }}>
                    <InputSelect
                      name="permissions"
                      options={options}
                      placeholder="Permissões"
                    />
                  </div>
                  <Button type="submit">Novo usuário</Button>
                </Form>
              </AnimationContainer>
            </Content>
          </Container>
        </>
      )}
    </>
  );
};

export default CreateUserWeb;
